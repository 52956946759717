<template>
  <div>
    <!-- vista usuarios ostrail -->
    <div v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
      <!-- filters -->
      <filter-club @filter-query="handleFilters" />
      <!--/ filters -->
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row
          v-if="!applyOverlay"
          class="mt-1"
        >
          <!-- agregar club -->
          <b-col
            sm="6"
            md="4"
            lg="3"
          >
            <b-card
              :img-src="require('@/assets/images/profile/user-uploads/green.jpg')"
              :img-alt="$t('clubs.corporate.clubLogo')"
              img-top
              class="card-profile img-card"
            >
              <div class="i-min-height-content-card">
                <b-link :to="{ name: 'create-club'}">
                  <div class="profile-image-wrapper">
                    <div class="profile-image p-0 img-cards">
                      <feather-icon
                        icon="PlusIcon"
                        size="110"
                        class="text-dark p-2"
                      />
                    </div>
                  </div>
                  <h4 class="mb-1 img-cards">
                    {{ $t('clubs.corporate.registerClub') }}
                  </h4>
                </b-link>
                <span class="text-muted mb-5">
                  {{ $t('clubs.corporate.articlesOfIncorporation') }}
                </span>
                <br>
                <span>&nbsp;</span>
              </div>
            </b-card>
          </b-col>
          <!--/ agregar club-->
          <!-- listar clubs-->
          <b-col
            v-for="club in clubs"
            :key="club.hash"
            sm="6"
            md="4"
            lg="3"
          >
            <b-card
              :img-src="club.cover_image_media_file != null ? path + club.cover_image_media_file.url_relative : require('@/assets/images/profile/user-uploads/green.jpg')"
              img-alt="Club Cover Photo"
              img-top
              class="card-profile img-card"
            >
              <div class="i-min-height-content-card">
                <b-link :to="{ name: 'show-club', params: { id: club.hash } }">
                  <div class="profile-image-wrapper">
                    <div class="profile-image p-0 img-cards">
                      <b-avatar
                        size="110"
                        variant="light"
                        :src="club.club_image_media_file == null ? null : path+club.club_image_media_file.url_relative"
                        class="i-shadow-t"
                      />
                    </div>
                  </div>
                  <h4 class="img-cards">
                    {{ club.club_name }}
                  </h4>
                </b-link>
                <h6 class="text-muted">
                  {{ $t('clubs.corporate.administrators') }}:
                  <br>
                  <strong
                    v-for="(adminCl, index) in club.administrators"
                    :key="adminCl.id"
                  >
                    <span v-if="index != 0">,</span>
                    <span>
                      {{ adminCl.full_name }}
                    </span>
                  </strong>
                </h6>
                <b-badge
                  id="status-badge"
                  class="pe-auto profile-badge btn-cursor img-cards"
                  pill
                  :variant="club.status == 1 ? 'light-primary' : 'light-danger'"
                  @click="changeStatus(club.hash)"
                >
                  {{ club.status == 1 ? $t('generic.active') : $t('generic.inactive') }}
                </b-badge>
                <b-tooltip
                  target="status-badge"
                  placement="bottom"
                >
                  <strong>{{ $t('generic.clickToChangeStatus') }}</strong>
                </b-tooltip>
              </div>
            </b-card>
          </b-col>
        <!--/ listar clubs-->
        </b-row>
        <b-row v-else>
          <b-col
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.total"
        :per-page="paginate.perPage"
        class="pt-2"
        first-number
        align="center"
        last-number
        @page-click="handleCurrentPage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <!--/ vista usuarios ostrail -->
    <!-- vista administradores de club -->
    <div v-else>
      <div v-if="clubInfo.club_name !== undefined && checkPermission(['EDIT_CLUB'])">
        <b-card class="py-5 i-min-height-fp">
          <b-row class="mt-5">
            <b-col
              sm="8"
              md="6"
              lg="4"
              class="offset-sm-2 offset-md-3 offset-lg-4 text-center"
            >
              <h3 class="mb-1">
                {{ $t('clubs.corporate.registerClub') }}
              </h3>
              <p class="mt-3">
                {{ $t('clubs.corporate.articlesOfIncorporation') }}
              </p>
              <b-button
                variant="primary"
                pill
                class="mt-3"
                :style="colorPrimaryBtn"
                @click="$router.push({ name: 'admin-create-club' })"
              >
                &nbsp; &nbsp;{{ $t('clubs.corporate.begin') }}&nbsp; &nbsp;
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <!-- vista administradores de club -->
      <!-- vista usuarios no administradores de club -->
      <div v-else>
        <b-card class="py-5 i-min-height-fp">
          <b-row class="mt-5">
            <b-col
              sm="8"
              md="6"
              lg="4"
              class="offset-sm-2 offset-md-3 offset-lg-4 text-center"
            >
              <h3 class="mb-1">
                {{ $t('clubs.corporate.notPossible') }}
              </h3>
              <p class="mt-3">
                {{ $t('clubs.corporate.tryLater') }}
              </p>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <!--/ vista usuarios no administradores de club -->
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters } from 'vuex'
import { isObject } from '@vueuse/core'
import { BCard, BRow, BCol, BAvatar, BLink, BBadge, BPagination, BButton, BOverlay } from 'bootstrap-vue'
import checkPermission from '@/auth/permissions'
import servicesClubs from '@/services/clubsService'
import FilterClub from './FilterClub.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BButton,
    BOverlay,
    FilterClub,
  },

  data() {
    return {
      clubs: [],
      applyOverlay: false,
      filters: {
        name: null,
        country_hash: null,
        state_hash: null,
        status: null,
      },
      paginate: {
        page: 1,
        perPage: 7,
        total: 0,
      },
    }
  },

  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
      clubInfo: 'clubInfo',
      role: 'role',
      path: 'path',
      userId: 'id',
    }),
  },

  mounted() {
    if (this.checkPermission(['VIEW_CLUB'])) {
      if (this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.getClubs(this.paginate, this.filters)
      } else if (
        (isObject(this.clubInfo.societies) || this.clubInfo.societies.length) &&
        (isObject(this.clubInfo.assign_representatives) || this.clubInfo.assign_representatives.length)
      ) {
        this.$router.push({ name: 'show-club', params: { id: this.clubInfo.hash } })
      }
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },

  methods: {
    checkPermission,
    handleCurrentPage(event, pageNumber) {
      this.paginate.page = pageNumber
      this.getClubs(this.paginate, this.filters)
    },
    getClubs(params = {}, filters = {}) {
      this.applyOverlay = true
      servicesClubs.getClubs(params, filters)
        .then(({ data }) => {
          this.clubs = data.data
          this.paginate.page = data.current_page
          this.paginate.perPage = data.per_page
          this.paginate.total = data.total
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
    },
    changeStatus(club) {
      servicesClubs.statusClubs(club)
        .then(({ data }) => {
          const index = this.clubs.findIndex(e => e.hash == club)
          this.clubs[index].status = data.data.status
          this.getClubs(this.paginate, this.filters)
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.filters.name = filterSelect.search
        this.filters.country_hash = filterSelect.idCountry
        this.filters.state_hash = filterSelect.idState
        this.filters.status = filterSelect.status
      }
      this.getClubs(this.paginate, this.filters)
    },
  },
}
</script>

<style scoped>
.i-min-height-content-card {
  min-height: 130px;
}

.i-min-height-fp {
  min-height: 550px !important;
}

.btn-cursor {
  cursor: pointer;
}

img {
  width: auto;
  min-height: 150px !important;
}
</style>
